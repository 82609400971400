<script setup lang="ts">
  interface Props {
    itemWidth?: number
    showArrows?: boolean
    itemGap?: string
    arrowsPos?: string
  }

  const props = withDefaults(defineProps<Props>(), {
    itemWidth: 0,
    showArrows: false,
    itemGap: '0px',
    arrowsPos: '8px'
  });

  const carousel = ref<HTMLDivElement>();

  const itemsLength = computed(() => {
    return carousel.value?.children.length ?? 0;
  });

  const itemDefaultWidth = computed(() => {
    if (props.itemWidth !== 0) {
      return props.itemWidth;
    }
    return (carousel.value?.children[0] as HTMLElement)?.offsetWidth ?? 0;
  });

  const totalItemsWidth = computed(() => {
    return itemDefaultWidth.value * itemsLength.value;
  });

  const visibleWidth = computed(() => {
    return carousel.value?.offsetWidth ?? 0;
  });

  const isHiddenLeftArrow = ref(true);

  let isDown = false;
  let startX = 0;
  let scrollLeft = 0;

  useEventListener(carousel, 'mousedown', (e) => {
    if (carousel.value) {
      isDown = true;
      carousel.value.classList.add('active');
      startX = e.pageX - carousel.value.offsetLeft;
      scrollLeft = carousel.value.scrollLeft;
    }
  });

  useEventListener(carousel, 'mouseleave', () => {
    if (carousel.value) {
      isDown = false;
      carousel.value.classList.remove('active');
    }
  });

  useEventListener(carousel, 'mouseup', () => {
    if (carousel.value) {
      isDown = false;
      carousel.value.classList.remove('active');
    }
  });

  useEventListener(carousel, 'mousemove', (e) => {
    if (carousel.value) {
      if (!isDown) { return; }
      e.preventDefault();
      const x = e.pageX - carousel.value.offsetLeft;
      const walk = (x - startX) * 3; // scroll-fast
      carousel.value.scrollLeft = scrollLeft - walk;
      if (carousel.value.scrollLeft === 0) {
        isHiddenLeftArrow.value = true;
      } else {
        isHiddenLeftArrow.value = false;
      }
    }
  });

  // let scrollPosition = 0;
  const scrollPosition = ref(0);

  const move = (direction: 'left'|'right') => {
    if (!carousel.value) {
      return;
    }
    if (direction === 'left') {
      scrollPosition.value = Math.max(scrollLeft - itemDefaultWidth.value, 0);
      if (scrollPosition.value === 0) {
        isHiddenLeftArrow.value = true;
      } else {
        isHiddenLeftArrow.value = false;
      }
      carousel.value?.scrollTo({
        left: scrollPosition.value,
        behavior: 'smooth'
      });
    } else {
      isHiddenLeftArrow.value = false;
      scrollPosition.value = Math.min(scrollLeft + itemDefaultWidth.value, totalItemsWidth.value - visibleWidth.value);
      carousel.value?.scrollTo({
        left: scrollPosition.value,
        behavior: 'smooth'
      });
    }
  };
</script>

<template>
  <div class="carousel-with-arrows">
    <div ref="carousel" class="carousel-container">
      <slot></slot>
      <ClientOnly v-if="showArrows && totalItemsWidth > visibleWidth">
        <div class="chevron-container left" :class="{hidden: isHiddenLeftArrow}" @click="move('left')">
          <IconChevronDown class="nav-chevron" />
        </div>
        <div class="chevron-container right" @click="move('right')">
          <IconChevronDown class="nav-chevron" />
        </div>
      </ClientOnly>
    </div>
  </div>
</template>

<style scoped lang="scss">
.carousel-with-arrows {
  position: relative;
  width: 100%;
}
.carousel-container {
  display: flex;
  gap: v-bind(itemGap);
  width: 100%;
  overflow: scroll;
  user-select: none;

  overflow-y: hidden;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  &::-webkit-scrollbar { /* WebKit */
    -webkit-appearance: none;
    display: none;
    width: 0;
    height: 0;
  }
}
.chevron-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 100px;
  background-color: white;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  user-select: none;
  @media (max-width: 1023px) {
    display: none;
  }
}
.nav-chevron {
  font-size: 32px;
  margin: 0;
}
.left {
 z-index: 10;
 position: absolute;
 left: v-bind(arrowsPos);
 top: 50%;
 transform: translate(0,-50%);
 & .nav-chevron {
  transform: rotate(90deg);
 }
}
.right {
 z-index: 10;
 position: absolute;
 right: v-bind(arrowsPos);
 top: 50%;
 transform: translate(0,-50%);
 & .nav-chevron {
  transform: rotate(270deg);
 }
}
.hidden {
  display: none;
}
</style>
